import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Contact from "./pages/Contact";
import FaQ from "./pages/FaQ";
//Pages
import Home from "./pages/Home";
import Houserules from "./pages/Houserules";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import PrivacyPolice from "./pages/PrivacyPolice";
import Register from "./pages/Register/component";
import RestaurantLoad from "./pages/Restaurant";
import Rewards from "./pages/Rewards";
import TakeAway from "./pages/TakeAway";
import TnC from "./pages/TnC";
import Whatson from "./pages/Whatson";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/home" component={Home} exact />
      {/* <Route path="/takeawaydelivery" component={TakeAway} exact /> */}
      <Route path="/whats-on" component={Whatson} exact />
      <Route path="/rewards" component={Rewards} exact />
      <Route path="/faq" component={FaQ} exact />
      <Route path="/tnc" component={TnC} exact />
      <Route path="/privacy-policy" component={PrivacyPolice} exact />
      <Route path="/houserules" component={Houserules} exact />
      <Route path="/contact" component={Contact} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/register" component={Register} exact />
      <Route path="/restaurant/:link" component={RestaurantLoad} exact />
      <Route path="/restaurant/:link/menu" component={RestaurantLoad} exact />
      <Route path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
