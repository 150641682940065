import React from "react";
import { Container } from "react-bootstrap";

export const SectionAbout = ({
  images,
  description,
  headline,
  logoDesktop,
  logoMobile,
}) => {
  return (
    <section className="section-about py-main">
      <Container className="container-md">
        {headline ? <h2>{headline}</h2> : null}
        <p className="animated fadeInUp delayp1">{description}</p>
      </Container>
      <Container className="animated brand-list fadeInUp delayp2">
        <img
          src={logoDesktop}
          alt="list-resto"
          className="img-fluid d-none d-md-block"
        />
        <img
          src={logoMobile}
          alt="list-resto"
          className="img-fluid d-md-none"
        />
      </Container>
    </section>
  );
};
